import React, { useContext, useEffect, useState } from "react";
import styles from "./InteriorItem.module.css";
import InputSelect from "../CommonSelect";
import InventoryQuestionService from "services/InventoryQuestionService";
import { Select } from "antd";
import { ModalContext } from "..";

const InteriorItem = ({ title, imageSrc, rating, otherData, id, dataArray, questionsList }) => {

  const {
    hovered,
    setHovered,
    editType,
    setEditType,
    handleMouseEnter,
    handleMouseLeave,
    showEditModal,
    setShowEditModal,
    editText,
    setEditText,
    EditOutlined,
    handleTextSubmit,
    popconfirmVisible,
    setPopconfirmVisible,
    editAccess
  } = useContext(ModalContext);

  const [questionEdit, setQuestionEdit] = useState(false);
  const [questionValue, setQuestionvalue] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [choices, setChoices] = useState([]);
  const [body, setBody] = useState({})

  const [flag, setFlag] = useState(false);
  const {Option} = Select;

  const selectChange = async (value) => {
    await setQuestionvalue(value);

    await setBody({ details: questionsList?.map((item)=>{
      if(item.questionId==id){
        return ({questionId: id, answer: value})
      }else{
        return item;
      }
    }) })
    setFlag(true);
  };

  useEffect(() => {
    if (flag) {
      console.log('select body is', body)
      handleTextSubmit(body, 'inspectionEdit');
      setFlag(false);
    }
  }, [flag]);


  const onClickDiv = async() => {
    console.log('entered here')
    setQuestionEdit(true);
    
if(selectedQuestion!=id && editAccess){
    const response = await InventoryQuestionService.get(id);
    console.log('response is', response)
    const resArray = await response?.data?.choices?.map((item)=>({_id: item.value, name: item.value}))
    console.log('choices are', resArray)
    setChoices(resArray)
}
setSelectedQuestion(id)
  }

  

  return (
    <div className={styles.item}>
      <div data-aos="fade-right" className={styles.title}>
        {title}
      </div>
      <div
        data-aos="fade-right"
        className={styles.ratingDiv}
        onClick={onClickDiv}
      >
        {questionEdit && editAccess ? (
          // <InputSelect
          // body={{ details: questionsList?.map((item)=>{
          //   if(item._id==id){
          //     return ({questionId: id, answer: questionValue})
          //   }else{
          //     return item;
          //   }
          // }) }}
          //   editSection={setQuestionEdit}
          //   valueStore={setQuestionvalue}
          //   value={questionValue}
          //   editMode="inspectionEdit"
          //   data={choices}
          //   defaultOption = {rating}
          // />
          <Select
      onBlur={() => {
       setQuestionEdit(false);
      }}
      onChange={selectChange}
      className="common-select"
      placeholder="Select an option"
      defaultValue = {rating ? rating : null}
    >
      {choices.map((item) => (
        <Option value={item._id}>{item.name}</Option>
      ))}
    </Select>
        ) : (
          <>
            {rating && <p className={styles.rating}>{rating}</p>}
            {otherData && <p className={styles.rating}>{otherData}</p>}
            {imageSrc && (
              <img
                loading="lazy"
                src={imageSrc}
                className={styles.image}
                alt={title}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InteriorItem;

import React, { useContext, useState } from "react";
import styles from "./VahanDetails.module.css";
import DetailRow from "./DetailRow";
import { Link, Element, animateScroll as scroll } from "react-scroll";
import VehicleDetails from "../car-inspection/VehicleDetails";
import { Select } from "antd";
import { ModalContext } from "..";

const VahanDetails = ({ vahanDetails, inventoryData }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [inputValue, setInputValue] = useState("");

  const { Option } = Select;

  const dataCheck = (value) => {
    if (value) {
      return value;
    } else {
      return "N/A";
    }
  };

  const {
    hovered,
    setHovered,
    editType,
    setEditType,
    handleMouseEnter,
    handleMouseLeave,
    showEditModal,
    setShowEditModal,
    editText,
    setEditText,
    EditOutlined,
    handleTextSubmit,
    popconfirmVisible,
    setPopconfirmVisible,
    editAccess
  } = useContext(ModalContext);

  const details = [
    {
      label: "OWNER NAME",
      value: dataCheck(vahanDetails?.owner),
      index: "owner_name",
      type: "input",
      apiParameter: "owner",
    },
    {
      label: "PRESENT ADDRESS",
      value: dataCheck(vahanDetails?.presentAddress),
      index: "present_address",
      type: "textarea",
      apiParameter: "presentAddress",
    },
    {
      label: "PERMANENT ADDRESS",
      value: dataCheck(vahanDetails?.permanentAddress),
      index: "permanentAddress",
      type: "textarea",
      apiParameter: "permanentAddress",
    },
    {
      label: "FINANCIER",
      value: dataCheck(vahanDetails?.rcFinancer),
      index: "financier",
      type: "input",
      apiParameter: "rcFinancer",
    },
    {
      label: "INSURER",
      value: dataCheck(vahanDetails?.vehicleInsuranceCompanyName),
      index: "insurer",
      type: "input",
      apiParameter: "vehicleInsuranceCompanyName",
    },
    {
      label: "REGISTRATION NUMBER",
      value: dataCheck(vahanDetails?.regNo),
      index: "regNo",
      type: "input",
      apiParameter: "regNo",
    },
    {
      label: "REGISTRATION DATE",
      value: dataCheck(vahanDetails?.regDate),
      index: "regDate",
      type: "date",
      apiParameter: "regDate",
    },
    {
      label: "REGISTERED AT RTO",
      value: dataCheck(vahanDetails?.rtoCode),
      index: "regRto",
      type: "input",
      apiParameter: "rtoCode",
    },
    {
      label: "RC STATUS",
      value: dataCheck(vahanDetails?.status),
      index: "rc_status",
      type: "input",
      apiParameter: "status",
    },
    {
      label: "MANUFACTURING DATE",
      value: dataCheck(vahanDetails?.vehicleManufacturingMonthYear),
      index: "mfg_date",
      type: "date",
      apiParameter: "vehicleManufacturingMonthYear",
    },
    {
      label: "VEHICLE MODEL",
      value: dataCheck(vahanDetails?.model),
      index: "vehicle_model",
      type: "input",
      apiParameter: "model",
    },
    {
      label: "COLOR",
      value: dataCheck(vahanDetails?.vehicleColour),
      index: "color",
      type: "input",
      apiParameter: "vehicleColour",
    },
    {
      label: "FUEL",
      value: dataCheck(vahanDetails?.type),
      index: "fuel",
      type: "input",
      apiParameter: "type",
    },
    {
      label: "CHASSIS NUMBER",
      value: dataCheck(vahanDetails?.chassis),
      index: "chassis_number",
      type: "input",
      apiParameter: "chassis",
    },
    {
      label: "FUEL NORMS",
      value: dataCheck(vahanDetails?.normsType),
      index: "fuel_norms",
      type: "input",
      apiParameter: "normsType",
    },
    {
      label: "VEHICLE CATEGORY",
      value: dataCheck(vahanDetails?.vehicleCategory),
      index: "veh_category",
      type: "input",
      apiParameter: "vehicleCategory",
    },
    {
      label: "VEHICLE CLASS DESCRIPTION",
      value: dataCheck(vahanDetails?.class),
      index: "veh_class_description",
      type: "textarea",
      apiParameter: "class",
    },
    {
      label: "BLACKLIST STATUS",
      value: dataCheck(vahanDetails?.blacklistStatus),
      index: "blacklist_status",
      type: "input",
      apiParameter: "blacklistStatus",
    },
    {
      label: "BODY TYPE",
      value: dataCheck(vahanDetails?.bodyType),
      index: "body_type",
      type: "input",
      apiParameter: "bodyType",
    },
    {
      label: "NOC DETAILS",
      value: dataCheck(vahanDetails?.nocDetails),
      index: "noc_details",
      type: "input",
      apiParameter: "nocDetails",
    },
    {
      label: "MOBILE NO",
      value: dataCheck(vahanDetails?.mobileNumber),
      index: "mobile_no",
      type: "input",
      apiParameter: "mobileNumber",
    },
    {
      label: "PERMIT NO",
      value: dataCheck(vahanDetails?.permitNumber),
      index: "permit_no",
      type: "input",
      apiParameter: "permitNumber",
    },
    {
      label: "PERMIT ISSUE DATE",
      value: dataCheck(vahanDetails?.permitIssueDate),
      index: "permit_issue_date",
      type: "date",
      apiParameter: "permitIssueDate",
    },
    {
      label: "RC TAX VALIDITY",
      value: dataCheck(vahanDetails?.vehicleTaxUpto),
      index: "rc_tax_validity",
      type: "date",
      apiParameter: "vehicleTaxUpto",
    },
    {
      label: "PERMIT VALID FROM DATE",
      value: dataCheck(vahanDetails?.permitValidFrom),
      index: "permit_valid_from",
      type: "date",
      apiParameter: "permitValidFrom",
    },
    {
      label: "INSURANCE POLICY NUMBER",
      value: dataCheck(vahanDetails?.vehicleInsurancePolicyNumber),
      index: "policy_no",
      type: "input",
      apiParameter: "vehicleInsurancePolicyNumber",
    },
    {
      label: "PERMIT VALID UPTO DATE",
      value: dataCheck(vahanDetails?.permitValidUpto),
      index: "permit_valid_to",
      type: "date",
      apiParameter: "permitValidUpto",
    },
    {
      label: "INSURANCE VALIDITY",
      value: dataCheck(vahanDetails?.vehicleInsuranceUpto),
      index: "insurance_validity",
      type: "date",
      apiParameter: "vehicleInsuranceUpto",
    },
    {
      label: "PERMIT TYPE",
      value: dataCheck(vahanDetails?.permitType),
      index: "permit_type",
      type: "input",
      apiParameter: "permitType",
    },
    {
      label: "RC STATUS AS ON",
      value: dataCheck(vahanDetails?.statusAsOn),
      index: "rc_on",
      type: "date",
      apiParameter: "statusAsOn",
    },
  ];

  const selectChange = (value) => {

    const body = {showpdfvahan: value};

    handleTextSubmit(body, 'inventoryEdit');

  };

  return (
    <Element name="vahan-details">
      <div className={styles.container}>
        <div className="title-container">
        <div className={styles.titleContainer}>
          <h1 className={styles.title} data-aos="fade-right">
            VAHAN DETAILS
          </h1>
          <div className={styles.showSection} id="mySelect">
            <p>Show Vahan Details :&nbsp;</p>
          <Select
            onChange={selectChange}
            className="details-select"
            placeholder="Show Vahan Details"
            dropdownStyle={{ fontSize: "12px" }}
            defaultValue={inventoryData?.showpdfvahan ? 'true' : 'false'}
          >
            <Option value='true'>Yes</Option>
            <Option value='false'>No</Option>
          </Select>
          </div>
        </div>
        </div>
        <div className={styles.detailsContainer}>
          {details.map((detail, index) => (
            <DetailRow
              key={index}
              detail={detail}
              label={detail.label}
              value={detail.value}
              inventoryData={inventoryData}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          ))}
        </div>
      </div>
    </Element>
  );
};

export default VahanDetails;

import React from 'react'
import InteriorGrid from './InteriorGrid';
import styles from './InteriorComponent.module.css'



function InteriorComponent({title, dataArray, questionsList}) {



  return (
    <div className={styles.interiorOuter}>
        <h2 data-aos="fade-right">{title}</h2>
      <InteriorGrid dataArray = {dataArray} questionsList = {questionsList} />
    </div>
  )
}

export default InteriorComponent

import React, { useContext, useEffect, useState } from "react";
import styles from "./DetailRow.module.css";
import { DatePicker, Input, Select } from "antd";
import { ModalContext } from "..";
import moment from "moment";

const isISODate = (dateString) => {
  // Check if the date is in valid ISO format YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss.sssZ
  const isoFormat = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(.\d{3}Z)?)?$/;
  return isoFormat.test(dateString);
};

const convertToISO = (dateString) => {
  if(dateString == ''){
    return
  }
  // Split the date based on possible delimiters
  const delimiters = ["/", "-", "."];
  let parts = [];

  for (let delimiter of delimiters) {
    if (dateString?.includes(delimiter)) {
      parts = dateString.split(delimiter);
      break;
    }
  }

  // Assume DD/MM/YYYY format if parts has 3 elements
  if (parts.length === 3) {
    const [day, month, year] = parts;
    return `${year}-${month}-${day}`;
  }

  // Return original string if format is not recognized
  return dateString;
};

// Define possible date formats
const formats = ['YYYY-MM-DD', 'DD/MM/YYYY', 'MM/YYYY'];

// Function to parse date
const parseDate = (dateString) => {
  let date = null;

  for (const format of formats) {
    date = moment(dateString, format, true);
    if (date.isValid()) {
      return date;
    }
  }

  return null; // Return null if no valid format is found
};

const DetailRow = ({
  label,
  value,
  inventoryData,
  detail,
  isEditing,
  setIsEditing,
  selectedIndex,
  setSelectedIndex,
  inputValue,
  setInputValue,
}) => {
  const {
    hovered,
    setHovered,
    editType,
    setEditType,
    handleMouseEnter,
    handleMouseLeave,
    showEditModal,
    setShowEditModal,
    editText,
    setEditText,
    EditOutlined,
    handleTextSubmit,
    popconfirmVisible,
    setPopconfirmVisible,
    editAccess,
  } = useContext(ModalContext);

  const [inventoryDetails, setInventoryDetails] = useState({})

  useEffect(()=>{
    setInventoryDetails({
      ...inventoryData?.vahanDetails,
      statusAsOn: isISODate(inventoryData?.vahanDetails?.statusAsOn)
        ? inventoryData?.vahanDetails?.statusAsOn
        : convertToISO(inventoryData?.vahanDetails?.statusAsOn),
      regDate: isISODate(inventoryData?.vahanDetails?.regDate)
        ? inventoryData?.vahanDetails?.regDate
        : convertToISO(inventoryData?.vahanDetails?.regDate),
      rcExpiryDate: isISODate(inventoryData?.vahanDetails?.rcExpiryDate)
        ? inventoryData?.vahanDetails?.rcExpiryDate
        : convertToISO(inventoryData?.vahanDetails?.rcExpiryDate),
      vehicleTaxUpto: isISODate(inventoryData?.vahanDetails?.vehicleTaxUpto)
        ? inventoryData?.vahanDetails?.vehicleTaxUpto
        : convertToISO(inventoryData?.vahanDetails?.vehicleTaxUpto),
      vehicleInsuranceUpto: isISODate(
        inventoryData?.vahanDetails?.vehicleInsuranceUpto
      )
        ? inventoryData?.vahanDetails?.vehicleInsuranceUpto
        : convertToISO(inventoryData?.vahanDetails?.vehicleInsuranceUpto),
      puccUpto: isISODate(inventoryData?.vahanDetails?.puccUpto)
        ? inventoryData?.vahanDetails?.puccUpto
        : convertToISO(inventoryData?.vahanDetails?.puccUpto),
      permitIssueDate: isISODate(inventoryData?.vahanDetails?.permitIssueDate)
        ? inventoryData?.vahanDetails?.permitIssueDate
        : convertToISO(inventoryData?.vahanDetails?.permitIssueDate),
      permitValidFrom: isISODate(inventoryData?.vahanDetails?.permitValidFrom)
        ? inventoryData?.vahanDetails?.permitValidFrom
        : convertToISO(inventoryData?.vahanDetails?.permitValidFrom),
      permitValidUpto: isISODate(inventoryData?.vahanDetails?.permitValidUpto)
        ? inventoryData?.vahanDetails?.permitValidUpto
        : convertToISO(inventoryData?.vahanDetails?.permitValidUpto),
      nonUseFrom: isISODate(inventoryData?.vahanDetails?.nonUseFrom)
        ? inventoryData?.vahanDetails?.nonUseFrom
        : convertToISO(inventoryData?.vahanDetails?.nonUseFrom),
      nonUseTo: isISODate(inventoryData?.vahanDetails?.nonUseTo)
        ? inventoryData?.vahanDetails?.nonUseTo
        : convertToISO(inventoryData?.vahanDetails?.nonUseTo),
      nationalPermitUpto: isISODate(
        inventoryData?.vahanDetails?.nationalPermitUpto
      )
        ? inventoryData?.vahanDetails?.nationalPermitUpto
        : convertToISO(inventoryData?.vahanDetails?.nationalPermitUpto),
    })
  },[inventoryData])

  console.log('inventorty details are', inventoryDetails)

  const selectDefaultValue = (type, value) => {
    if (type == "input") {
      return value;
    }
    if (type == "textarea") {
      return value;
    }
    if (type == "date") {
      // const momentDate = moment(value, 'DD/MM/YYYY');
      // return momentDate
      let momentDate;
      let pickerType = "date"; // Default picker type

      // Check if the date is in DD/MM/YYYY or MM/YYYY format
      // if (value.length === 7) {
      //   // MM/YYYY format
      //   momentDate = moment(value, "MM/YYYY");
      //   pickerType = "month"; // Use month picker mode
      // } else {
      //   // Assume DD/MM/YYYY format
      //   momentDate = moment(value, "DD/MM/YYYY");
      //   pickerType = "date"; // Use date picker mode
      // }
      momentDate = parseDate(value);
      return momentDate;
    }
  };


  const handlePressEnter = () => {
    let body = {};

    body = {
      vahanDetails: {
        ...inventoryDetails,
        [selectedIndex?.apiParameter]: inputValue,
      },
    };

    console.log("body is", body, selectedIndex?.value);
    handleTextSubmit(body, 'inspectionEdit');
    // Handle the event here (e.g., submit the form, make an API call, etc.)
  };

  const handleDateChange = (date, dateString) => {
    let body = {};
    let isoString;
    // setDate(date);
    console.log("date value is", date, dateString); // date is a moment object, dateString is a formatted string
    if (date) {
      // isoString = date.toISOString();
      isoString = date.format('YYYY-MM-DD');
      // setDate(isoString);
      console.log(isoString, "iso date"); // Outputs the date in ISO 8601 format
    } else {
      // setDate(null);
    }

      body = {
        vahanDetails: {
          ...inventoryDetails,
          [selectedIndex?.apiParameter]: isoString,
        },
      };
      console.log("body is", body, selectedIndex);
    

    handleTextSubmit(body, 'inspectionEdit');

  };




  return (
    <div className={styles.row}>
      <div data-aos="fade-right" className={styles.label}>
        {detail?.label}
      </div>
      {isEditing && selectedIndex?.index == detail?.index ? (
        detail?.type == "input" ? (
          <Input
            className={styles.selectedInput}
            onBlur={() => setIsEditing(false)}
            defaultValue={() => selectDefaultValue(detail?.type, detail?.value)}
            onChange={(e) => setInputValue(e.target.value)}
            onPressEnter={handlePressEnter}
          />
        ) : detail?.type == "textarea" ? (
          <Input.TextArea
            onBlur={() => setIsEditing(false)}
            rows={3}
            className={styles.selectedInput}
            defaultValue={() => selectDefaultValue(detail?.type, detail?.value)}
            onChange={(e) => setInputValue(e.target.value)}
            onPressEnter={handlePressEnter}
          />
        ) : (
          <DatePicker
            className={styles.selectedInput}
            // defaultValue={selectDefaultValue(item)}
            onBlur={() => setIsEditing(false)}
            defaultValue={() => selectDefaultValue(detail?.type, detail?.value)}
            format="DD/MM/YYYY"
            onChange={handleDateChange}
          />
        )
      ) : (
        <div
          onClick={() => {
            setSelectedIndex(detail);
            setIsEditing(true);
          }}
          data-aos="fade-right"
          className={styles.value}
        >
          {detail?.value}
        </div>
      )}
    </div>
  );
};

export default DetailRow;

export const rolesData = {
    "client-executive": {
        module: "CLIENT EXECUTIVE",
        key: "client-executive",
        add: true,
        delete: true,
        edit: true,
        fetch: true,
    },
    "client": {
        module: "CLIENT",
        key: "client",
        add: true,
        delete: true,
        edit: true,
        fetch: true,
    },
    "inventory": {
        module: "INVENTORY",
        key: "inventory",
        add: true,
        delete: true,
        edit: true,
        fetch: true,
    },

};

